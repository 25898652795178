<template>
  <section>
    <TitleBar>
      Relev&eacute; de compte
      <template #toolbar-content>
        <HelpReleveCompteComponent></HelpReleveCompteComponent>
        &nbsp;
        <TableButtonGroup
          :tableKey="tableKey"
          @rechercher="rechercher"
        ></TableButtonGroup>
      </template>
    </TitleBar>
    <b-container fluid>
      <ErrorComponent :error="error" @hide="error = null"></ErrorComponent>
      <StrapiEditoComponent></StrapiEditoComponent>
      <PaginatedTable
        :fields="fields"
        :busy="busy"
        :pageable="pageable"
        :page="page"
        @change="pageableChanged"
      >
        <template #mobile-card="{ item }">
          <MouvementFinancierCard :item="item"></MouvementFinancierCard>
        </template>
        <template #cell(dateValeur)="{ item }">
          {{ isoSqlDateToFrenchDate(item.dateValeur) }}
        </template>
        <template #cell(dateEcheance)="{ item }">
          {{ isoSqlDateToFrenchDate(item.dateEcheance) }}
        </template>
        <template #cell(montantTtc)="{ item }">
          <MontantComponent
            :value="item.montantTtc"
            variantPositive="success"
            variantNegative="danger"
          ></MontantComponent>
        </template>
        <template #cell(solde)="{ item }">
          <MontantComponent
            :value="item.solde"
            variantPositive="success"
            variantNegative="danger"
          ></MontantComponent>
        </template>
        <template #cell(fichier)="{ item }">
          <DownloadComponent
            v-if="item.pieceJointe"
            :typeDocument="typeDocument"
            :targetId="item.idMouvement"
          ></DownloadComponent>
        </template>
        <template #pagination>
          Solde :
          <strong>
            <MontantComponent
              :value="solde"
              :options="{ style: 'currency', currency: 'EUR' }"
              variantPositive="success"
              variantNegative="danger"
            ></MontantComponent>
          </strong>
        </template>
      </PaginatedTable>
    </b-container>
  </section>
</template>

<script>
import TitleBar from "../../components/TitleBar.vue";
import HelpReleveCompteComponent from "../../components/controls/HelpReleveCompteComponent.vue";
import TableButtonGroup from "../../components/controls/TableButtonGroup.vue";
import ErrorComponent from "../../components/ErrorComponent.vue";
import StrapiEditoComponent from "../../components/strapi/StrapiEditoComponent.vue";
import PaginatedTable from "../../components/PaginatedTable.vue";
import MouvementFinancierCard from "../../components/cards/MouvementFinancierCard.vue";
import MontantComponent from "../../components/MontantComponent.vue";
import DownloadComponent from "../../components/controls/DownloadComponent.vue";
import ExploitationService from "../../services/exploitation.service";
import { TableKeys } from "../../store/tables.defaults";
import {
  dataFactory as tableDataFactory,
  created,
  computed as tableComputed,
  methods as tableMethods,
} from "../../services/tables.service";
import UtilsService from "../../services/utils.service";
export default {
  name: "ReleveCompteView",
  components: {
    TitleBar,
    HelpReleveCompteComponent,
    TableButtonGroup,
    ErrorComponent,
    StrapiEditoComponent,
    PaginatedTable,
    MouvementFinancierCard,
    MontantComponent,
    DownloadComponent,
  },
  data() {
    return {
      ...tableDataFactory(TableKeys.RELEVE_COMPTE),
      typeDocument: this.$store.state.expl.typesDocument.FACTURE,
      error: null,
      solde: 0,
      page: null,
    };
  },
  computed: {
    ...tableComputed,
    statuts() {
      return [
        { value: null, text: "Tous" },
        { value: true, text: "Soldés" },
        { value: false, text: "Non soldés" },
      ];
    },
    exploitationCourante() {
      return this.$store.getters["expl/adherentCourant"];
    },
  },
  created,
  mounted() {
    this.load();
  },
  methods: {
    ...tableMethods,
    isoSqlDateToFrenchDate: UtilsService.isoSqlDateToFrenchDate,
    async load() {
      this.error = null;
      try {
        this.busy = true;
        let response = await ExploitationService.getReleveCompte(
          this.exploitationCourante.codeTiers,
          this.getSearchPayload()
        );
        this.solde = response.data.solde ?? 0.0;
        this.page = response.data.page;
      } catch (error) {
        this.solde = 0;
        this.page = null;
        this.error = UtilsService.handleError(error);
      } finally {
        this.busy = false;
      }
    },
  },
};
</script>
